export default {
    image_path: process.env.PUBLIC_URL + '/uploads/',
    development: {
        api_url: 'http://new20.digi-chat.com',
        aip_pi_url: 'http://new20.digi-chat.com',
        api_v: '/api/',
        upload_url: 'http://localhost:9000/upload',
        admin_panel_url: '/mychatapp/build/',
        client_socket_port: ':9000',
        daily_messages_period: 1000 * 60 * 5,
        user_typing_times: 8,
        number_of_messages: 20,
        cleaning_messages_period: 10000,
        room_expulsion_period: 1000 * 60,
        monitoring_user_activity_period: 1000 * 60,
        message_input_pause_time: 10000 * 30,
    },
    production: {
        api_url: 'https://api.a7lamsr.net',
        aip_pi_url: 'https://api.a7lamsr.net',
        api_v: '/api/',
        upload_url: 'https://a7lamsr.net/upload',
        admin_panel_url: '/',
        client_socket_port: '',
        daily_messages_period: 1000 * 60 * 5,
        user_typing_times: 8,
        number_of_messages: 20,
        cleaning_messages_period: 1000 * 60,
        room_expulsion_period: 1000 * 60,
        monitoring_user_activity_period: 1000 * 60,
        message_input_pause_time: 10000 * 30,
    }
}
