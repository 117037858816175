import React, {Component} from 'react';
import Login from './Login';
import {Tabs, TabList, Tab, TabPanel} from 'react-tabs';
import {FaComment, FaPaperPlane, FaShare, FaUserAlt, FaUserPlus, FaUsers} from 'react-icons/fa';
import ListingUsers from './ListingUsers';
import {FiRefreshCw} from "react-icons/fi";
import Labels from '../labels';
import "react-tabs/style/react-tabs.css";
import {getConnectedUsersCount} from "../functions";
import Constants from "../constants";
import config from "../config";
import '../assets/sass/HomeLogin.scss';

class HomeLogin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            siteAdministrationContent: false,
            tabIndex: 1
        };
    }

    toggleSiteAdministrationContent = () => {
        const {siteAdministrationContent} = this.state;

        this.setState({
            siteAdministrationContent: !siteAdministrationContent
        });
    };

    render() {
        const {siteAdministrationContent} = this.state;
        const {
            socket, setUser, connectedUsers,
            siteSettings, buttonsColor,
            checkIfDeviceBlocked
        } = this.props;

        const logo = siteSettings.logo ? config.image_path + siteSettings.logo: null;
        const banner = siteSettings.banner ? config.image_path + siteSettings.banner : null;

        return (
            <div className="home-login">
                <div className="header" style={{
                    background: buttonsColor
                }}>
                    <a href="/" title={Labels.app_name}>
                        <div className="logo-app-name">
                            <span className="app-name">{siteSettings.title}</span>
                            <div className="logo">
                                <img src={logo} alt={Labels.app_name} className="img-responsive"/>
                            </div>
                        </div>
                        <button type="button" className="btn btn-sm btn-success btn-refresh">
                            <FiRefreshCw/>
                        </button>
                    </a>
                </div>
                <div className="home-login-site-image cover-bg" style={{
                    backgroundImage: "url(" + banner + ")"
                }}>
                </div>
                <div className="home-login-actions">
                    <button type="button" className="btn" style={{
                        backgroundColor: buttonsColor
                    }}>
                        {Labels.app}
                    </button>
                    <button type="button" className="btn" style={{
                        backgroundColor: buttonsColor
                    }}>
                        {Labels.rules}
                    </button>
                    <button type="button" className="btn" style={{
                        backgroundColor: buttonsColor
                    }}>
                        {Labels.subscriptions}
                    </button>
                </div>
                <div
                    className="site-administration"
                    onClick={this.toggleSiteAdministrationContent}
                    style={{
                        backgroundColor: buttonsColor
                    }}>
                    {Labels.site_admin}
                </div>
                <div className={`site-administration-content${siteAdministrationContent ? ' active' : ''}`}>
                    <div className="line">
                        <span>{Labels.site_admin}</span>
                        <FaPaperPlane/>
                    </div>
                    <div className="line">
                        <span>خالد الغلا</span>
                        <FaShare/>
                        <FaComment/>
                    </div>
                    <div className="line">
                        <span>نقاء</span>
                        <FaShare/>
                        <FaComment/>
                    </div>
                    <div className="line">
                        <FaShare/>
                        <FaComment/>
                    </div>
                </div>
                <div className="login-register">
                    <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ tabIndex })}>
                        <TabList>
                            {
                                siteSettings && siteSettings.allow_members && (
                                    <Tab>
                                        {Labels.register}
                                        <FaUserPlus/>
                                    </Tab>
                                )
                            }
                            <Tab>
                                {Labels.members_login}
                                <FaUserAlt/>
                            </Tab>
                            {
                                siteSettings && siteSettings.allow_gusts && (
                                    <Tab>
                                        {Labels.guests_login}
                                        <FaUserAlt/>
                                    </Tab>
                                )
                            }
                        </TabList>
                        {
                            siteSettings && siteSettings.allow_members && (
                                <TabPanel>
                                    <Login
                                        socket={socket}
                                        setUser={setUser}
                                        task={Constants.login_types.register}
                                        buttonsColor={buttonsColor}
                                        checkIfDeviceBlocked={checkIfDeviceBlocked}
                                    >
                                    </Login>
                                </TabPanel>
                            )
                        }
                        <TabPanel>
                            <Login
                                socket={socket}
                                setUser={setUser}
                                task={Constants.login_types.members_login}
                                buttonsColor={buttonsColor}
                                checkIfDeviceBlocked={checkIfDeviceBlocked}
                            >
                            </Login>
                        </TabPanel>
                        {
                            siteSettings && siteSettings.allow_gusts && (
                                <TabPanel>
                                    <Login
                                        socket={socket}
                                        setUser={setUser}
                                        task={Constants.login_types.guests_login}
                                        buttonsColor={buttonsColor}
                                        checkIfDeviceBlocked={checkIfDeviceBlocked}
                                    >
                                    </Login>
                                </TabPanel>
                            )
                        }
                    </Tabs>
                </div>
                <div className="connected-">
                    <span>&nbsp;</span>
                    <div className="connected">
                        {Labels.member_connected}
                    </div>
                </div>
                <div
                    className="connected-users-now-marquee"
                    style={{
                        background: buttonsColor
                    }}
                >
                    <div className="connected-users-now">
                        <FaUsers/>
                        <span className="label">{Labels.connected_now}</span>
                        <span className="jewel-count green">
                            {getConnectedUsersCount(connectedUsers)}
                        </span>
                    </div>
                    <div className="marquee">
                        <p>{siteSettings.welcome_message}</p>
                    </div>
                </div>
                <ListingUsers
                    users={connectedUsers}
                    task='HOME_LOGIN'
                />
            </div>
        );
    }
}

export default HomeLogin;
